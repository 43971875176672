import { useQueryClient } from '@tanstack/react-query'

import { useAuthMutation } from './auth'
import { ApiStudentRequest, request } from '../utils'
import {
  RefinedVersionedApplicationResourceWithFiles,
  UpdateFileMetaData,
} from 'applications-types-lib'
import { useS3Upload } from './useS3Upload'
import type { UploadFileData } from '../components/Application'
import type { RawApplicationAggregationResponse, RawApplicationResponse } from './useGetApplication'
import type { UpdateApplicationRequest } from '@backend/students-api/routes/applications/{id}/PATCH/types'

type UpdateApplicationProps = {
  id: string
}

type UpdateApplicationAttributes =
  | UpdateApplicationRequest['data']['attributes']
  | {
      educationHistory?: null
    }

type UpdateApplicationParams = {
  attributes?: UpdateApplicationAttributes
  files?: Record<string, UploadFileData | null>
}

type RawApplicationsResponse = {
  data: RefinedVersionedApplicationResourceWithFiles
}

function stripFileFromData(fileData?: Record<string, UploadFileData | null>) {
  if (!fileData || !Object.keys(fileData).length) return null

  const strippedFileData: Record<string, UpdateFileMetaData | null> = {}

  Object.keys(fileData).forEach(fileId => {
    if (fileData[fileId] === null) {
      strippedFileData[fileId] = null
      return
    }
    const { file, ...rest } = fileData[fileId] as UploadFileData
    strippedFileData[fileId] = rest
  })

  return strippedFileData
}

async function UpdateApplication({
  id,
  attributes,
}: {
  id?: string
  attributes: UpdateApplicationAttributes
}) {
  return request<RawApplicationsResponse>(
    new ApiStudentRequest(`/applications/${id}`, {
      method: 'PATCH',
      body: {
        data: {
          id: id,
          type: 'application',
          attributes,
        },
      },
    }),
    {
      isExpectedResponse: (res): res is RawApplicationsResponse => res,
    },
  )
}

export function useUpdateApplication(props: UpdateApplicationProps) {
  const { s3UploadAsync } = useS3Upload()
  const { pollForFileUploadCompletion } = usePollForFileUploadCompletion({
    id: props.id,
    options: {
      maxRetries: 150, // ~10 minutes at the interval below
      retryFn: (retries: number) => {
        return Math.min(4000, 2 ** retries * 500)
      },
    },
  })
  const { isPending, mutate, mutateAsync } = useAuthMutation(
    async (params: UpdateApplicationParams) => {
      //@ts-ignore
      const { humanReadableId, ...stripped } = params.attributes || {}
      const fileData = stripFileFromData(params.files)

      const attributes: UpdateApplicationAttributes = {
        ...stripped,
        ...(fileData ? { files: fileData } : {}),
      }

      let response = await UpdateApplication({ id: props.id, attributes })

      if (params.files && fileData && response.data.meta.files) {
        const fileParams = params.files as Record<string, UploadFileData | null> // typescript BS
        const fileMeta = response.data.meta.files
        const filesToUpload = Object.keys(fileParams).filter(fileId => {
          return fileParams[fileId] !== null && fileMeta[fileId]?.upload
        })

        const failedUploads: Record<string, null> = {}
        const s3Uploads = filesToUpload.map(fileId => {
          const upload = fileMeta[fileId].upload
          if (upload?.url && upload?.fields) {
            return s3UploadAsync({
              file: fileParams[fileId]?.file as File,
              url: upload.url,
              fields: upload.fields,
            }).catch(response => {
              failedUploads[fileId] = null
              throw new Error(response)
            })
          }

          failedUploads[fileId] = null
        })

        await Promise.allSettled(s3Uploads)

        if (Object.keys(failedUploads).length) {
          // Undo all file uploads until we have a way of returning the partially updated state to the form
          const filesToUndo: Record<string, UploadFileData | null> = {}
          Object.keys(fileParams).forEach(id => {
            if (fileParams[id] !== null) filesToUndo[id] = null
          })
          await UpdateApplication({ id: props.id, attributes: { files: filesToUndo } })

          throw new Error('Some files failed to upload, please try again')
        }

        response = await pollForFileUploadCompletion(filesToUpload)
      }

      return response
    },
  )

  return {
    isUpdatingApplication: isPending,
    updateApplication: mutate,
    updateApplicationAsync: mutateAsync,
  }
}

interface IUsePollForFileUploadCompletion {
  id: string
  options: {
    maxRetries: number
    retryFn: (retries: number) => number
  }
}

function usePollForFileUploadCompletion({ id, options }: IUsePollForFileUploadCompletion) {
  let retryCount = 0

  const queryClient = useQueryClient()

  function areFilesInUploadedState(applicationResponse: RawApplicationResponse, fileIds: string[]) {
    const files = applicationResponse.data.attributes?.files
    if (!files) return false
    return (
      fileIds.filter(id => {
        return files[id]?.uploadStatus !== 'UPLOADED'
      }).length === 0
    )
  }

  function sleep(time: number) {
    return new Promise(resolve => setTimeout(resolve, time))
  }

  async function getApplication(): Promise<RawApplicationResponse> {
    await queryClient.invalidateQueries({
      queryKey: ['applications', id],
    })
    const applicationAggregationResponse = queryClient.getQueryData([
      'applications',
      id,
    ]) as RawApplicationAggregationResponse

    return {
      data: applicationAggregationResponse.data.attributes.application,
    }
  }

  async function pollForFileUploadCompletion(fileIds: string[]): Promise<RawApplicationResponse> {
    let applicationResponse = await getApplication()

    while (
      !areFilesInUploadedState(applicationResponse, fileIds) &&
      retryCount < options.maxRetries
    ) {
      await sleep(options.retryFn(retryCount))
      applicationResponse = await getApplication()
      retryCount++
    }

    if (retryCount >= options.maxRetries) {
      throw new Error('There was a problem processing your file upload')
    }

    return applicationResponse
  }

  return {
    pollForFileUploadCompletion,
  }
}
