import { Flex, GridAlpha, Text } from '@applyboard/crystal-ui'
import { ApplicationDetailFile } from '../ApplicationDetailFile'
import { format } from 'date-fns'

type ApplicationDetailAdditionalDocumentsProps = Readonly<{
  files: Array<{
    hash: string
    fileName: string
    url: string
    uploadedAt: Date
  }>
}>

export function ApplicationDetailAdditionalDocuments(
  props: ApplicationDetailAdditionalDocumentsProps,
) {
  return (
    <GridAlpha
      columns={{
        xs: 'auto',
        lg: 'repeat(2, 1fr)',
      }}
      columnGap={20}
      rowGap={6}
    >
      {props.files.map(file => (
        <GridAlpha.Item key={file.hash}>
          <Flex direction="column" gap={2}>
            <ApplicationDetailFile name={file.fileName} url={file.url} />
            <Text variant="labelS" contrast="mid">
              Uploaded on {format(file.uploadedAt, 'dd MMM yyyy')}
            </Text>
          </Flex>
        </GridAlpha.Item>
      ))}
    </GridAlpha>
  )
}
