import { Button, Dialog } from '@applyboard/crystal-ui'
import { CheckMarkOutlineIcon, PlusOutlineIcon } from '@applyboard/ui-icons'
import { PropsWithChildren } from 'react'
import { IDocumentDialog } from '../types'
import { documentTypeOptions } from '../../../../utils'

export function DocumentDialog(props: PropsWithChildren<IDocumentDialog>) {
  if (props.document.isDocCompleted || props.document.requestStatus !== 'PENDING') {
    return (
      <Button
        emphasis="highlighted"
        intent="positive"
        disabled={!props.document.isDocCompleted && props.document.requestStatus === 'DISMISSED'}
        leadIcon={
          !props.document.isDocCompleted && props.document.requestStatus === 'DISMISSED'
            ? PlusOutlineIcon
            : CheckMarkOutlineIcon
        }
      >
        {documentTypeOptions[props.document.documentType].label}
      </Button>
    )
  }

  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <Dialog.Trigger>
        <Button emphasis="highlighted" intent="primary" leadIcon={PlusOutlineIcon}>
          {documentTypeOptions[props.document.documentType].label}
        </Button>
      </Dialog.Trigger>
      <Dialog.Heading>{props.heading}</Dialog.Heading>
      <Dialog.Content>{props.children}</Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
        <Button
          form={props.form}
          type="submit"
          width="fill"
          intent="primary"
          loading={props.loading}
        >
          Save and submit
        </Button>
      </Dialog.Actions>
    </Dialog>
  )
}
