import { Card, Flex, Heading, Text } from '@applyboard/crystal-ui'

import { ApplicationDetailCard } from './ApplicationDetailCard'
import { ApplicationDetailDecisionLetterHeading } from './ApplicationDetailDecisionLetterHeading'
import { useUpdateDecisionLetters } from '../../../hooks/useUpdateDecisionLetters'
import { RawApplicationAggregationResponse } from '../../../hooks'
import { useQueryClient } from '@tanstack/react-query'
import { ApplicationDetailAdditionalDocuments } from './ApplicationDetailAdditionalDocuments'

type ApplicationDetailsProps = Readonly<{
  applicationId: string
  onOfferConditionsClick: () => void
}>

export function ApplicationDetails(props: ApplicationDetailsProps) {
  const queryClient = useQueryClient()
  const { updateLettersDecision, isLoadingUpdateLettersDecision } = useUpdateDecisionLetters()

  return (
    <Flex direction="column" gap={6}>
      <Card>
        <Card.Header>
          <ApplicationDetailDecisionLetterHeading />
        </Card.Header>
        <Card.Divider mt={2} mb={3} />
        <Flex direction="column" gap={6}>
          <ApplicationDetailCard
            title="Pre-Offer"
            intent="positive"
            status="PENDING"
            issuedOn={new Date()}
            file={{
              name: 'Pre-Offer.pdf',
              url: '#',
            }}
            rejectDialog={{
              onSubmit: ({ onSuccess, onError, data }) => {
                updateLettersDecision(
                  {
                    applicationId: props.applicationId,
                    decision: {
                      studentResponse: 'REJECTED',
                      letterType: 'PRE_OFFER',
                      reason: data.reason,
                    },
                  },
                  {
                    onSuccess: response => {
                      queryClient.setQueryData(
                        ['applications', response.data.id],
                        (
                          oldData: RawApplicationAggregationResponse,
                        ): RawApplicationAggregationResponse => {
                          return {
                            data: {
                              ...oldData.data,
                              attributes: {
                                ...oldData.data.attributes,
                                application: response.data,
                              },
                            },
                          }
                        },
                      )
                      onSuccess()
                    },
                    onError,
                  },
                )
              },
            }}
            onOfferConditionsClick={props.onOfferConditionsClick}
            helperText="Please download the attached pre-offer letter, review the offer conditions, and respond."
            helperTextIntent="positive"
            loading={isLoadingUpdateLettersDecision}
            acceptDialog={{
              programName: 'Application for Computer Programming and Analysis',
              campusName: 'Downtown',
              intakeTermName: 'Spring 2025',
              onSubmit: ({ onSuccess, onError }) => {
                updateLettersDecision(
                  {
                    applicationId: props.applicationId,
                    decision: {
                      studentResponse: 'ACCEPTED',
                      letterType: 'PRE_OFFER',
                    },
                  },
                  {
                    onSuccess: response => {
                      queryClient.setQueryData(
                        ['applications', response.data.id],
                        (
                          oldData: RawApplicationAggregationResponse,
                        ): RawApplicationAggregationResponse => {
                          return {
                            data: {
                              ...oldData.data,
                              attributes: {
                                ...oldData.data.attributes,
                                application: response.data,
                              },
                            },
                          }
                        },
                      )

                      onSuccess()
                    },
                    onError,
                  },
                )
              },
            }}
          />
          <ApplicationDetailCard
            title="Final Offer"
            intent="positive"
            issuedOn={new Date()}
            file={{
              name: 'Final-Offer.pdf',
              url: '#',
            }}
            onOfferConditionsClick={props.onOfferConditionsClick}
            helperText="Please download the attached pre-offer letter, review the offer conditions, and respond."
            helperTextIntent="positive"
          />
          <ApplicationDetailCard
            title="Waitlist"
            intent="secondary"
            issuedOn={new Date()}
            file={{
              name: 'Waitlist-Letter.pdf',
              url: '#',
            }}
            onOfferConditionsClick={props.onOfferConditionsClick}
            helperText="Please download the attached pre-offer letter, review the offer conditions, and respond."
          />
          <ApplicationDetailCard
            title="Decline"
            intent="negative"
            issuedOn={new Date()}
            file={{
              name: 'Declined-Letter.pdf',
              url: '#',
            }}
            helperText="We regret to inform you that, after careful consideration of your application, the school has decided to decline your offer."
            helperTextIntent="negative"
          />
          <ApplicationDetailCard
            title="Pre-Offer"
            intent="positive"
            status="ACCEPTED"
            issuedOn={new Date()}
            statusChangedOn={new Date()}
            file={{
              name: 'Pre-Offer.pdf',
              url: '#',
            }}
            onOfferConditionsClick={props.onOfferConditionsClick}
          />
          <ApplicationDetailCard
            title="Pre-Offer"
            intent="positive"
            status="REJECTED"
            issuedOn={new Date()}
            statusChangedOn={new Date()}
            file={{
              name: 'Pre-Offer.pdf',
              url: '#',
            }}
            helperText="A decision has been made by you to reject this pre-offer letter."
            helperTextIntent="negative"
          />
        </Flex>
      </Card>

      <Card>
        <Card.Header>
          <Flex direction="column" gap={2}>
            <Flex.Item>
              <Text contrast="mid">20 DEC 2023</Text>
              <Heading level={2} variant="titleS">
                Additional Documents Received
              </Heading>
            </Flex.Item>
            <Text contrast="mid">
              The following documents have been shared by the school. Please download them, as they
              contain important information that will assist you in the next steps of the process.
            </Text>
          </Flex>
        </Card.Header>
        <Card.Divider mt={2} mb={3} />
        <ApplicationDetailAdditionalDocuments
          files={[
            {
              hash: '1',
              fileName: 'Additional Document 1',
              url: '#',
              uploadedAt: new Date(),
            },
            {
              hash: '2',
              fileName: 'Additional Document 2',
              url: '#',
              uploadedAt: new Date(),
            },
            {
              hash: '3',
              fileName: 'Additional Document 3',
              url: '#',
              uploadedAt: new Date(),
            },
          ]}
        />
      </Card>
    </Flex>
  )
}
