import { Card, DescriptionList, Flex, Heading, Link, Tag } from '@applyboard/crystal-ui'
import { IntakeSearchResource } from '@backend/resources/intake-search/types'
import { RefinedVersionedApplicationResourceWithFiles } from 'applications-types-lib'
import { format } from 'date-fns'
import { convertTimelessDateStrToLocalDate } from '../../../../utils/convertTimelessDateStrToLocalDate'
import { DeliveryMethod } from '../DeliveryMethod'
import { IntakeStatus } from '../IntakeStatus'
import { ProgramCardButton } from '../ProgramCardButton/ProgramCardButton'
import { ProgramLength } from '../ProgramLength'

type ProgramCardProps = {
  disabled?: boolean
  loading?: boolean
  onSubmit: () => void
  intake: IntakeSearchResource
  currentApplication: RefinedVersionedApplicationResourceWithFiles['attributes']
}

export function ProgramCard(props: ProgramCardProps) {
  const headingId = crypto.randomUUID()

  return (
    <Card as="article" aria-labelledby={headingId}>
      <Card.Header>
        <Heading level={2} id={headingId}>
          {props.intake.attributes.program.url ? (
            <Link
              href={props.intake.attributes.program.url}
              mode="override_user_choice_and_open_in_new_tab"
              variant="standalone"
            >
              {props.intake.attributes.program.name}
            </Link>
          ) : (
            props.intake.attributes.program.name
          )}
        </Heading>
      </Card.Header>
      <Flex gap={4} direction="column">
        <Flex gap={2} wrap>
          {props.intake.attributes.programLevel?.name ? (
            <Tag intent="secondary">{props.intake.attributes.programLevel.name}</Tag>
          ) : null}
          <ProgramLength
            length={props.intake.attributes.program.length}
            unit={props.intake.attributes.program.lengthUnit as 'years' | 'months' | 'weeks'}
          />
          <DeliveryMethod deliveryMethod={props.intake.attributes.program.deliveryMethod} />
        </Flex>
        <DescriptionList aria-label="Program Information" variant="free">
          <Flex direction="column" gap={2}>
            <Flex justify="between" gap={1}>
              <DescriptionList.Term>Campus</DescriptionList.Term>
              <DescriptionList.Details>
                {props.intake.attributes.campus.name}
              </DescriptionList.Details>
            </Flex>
            <Flex justify="between" gap={1}>
              <DescriptionList.Term>Start</DescriptionList.Term>
              <DescriptionList.Details>
                {format(
                  convertTimelessDateStrToLocalDate(props.intake.attributes.intakeTerm.startsOn),
                  'MMM yyyy',
                )}
              </DescriptionList.Details>
            </Flex>
            <Flex justify="between" gap={1}>
              <DescriptionList.Term>Status</DescriptionList.Term>
              <DescriptionList.Details>
                <IntakeStatus status={props.intake.attributes.intake.status} />
              </DescriptionList.Details>
            </Flex>
          </Flex>
        </DescriptionList>
      </Flex>
      <Card.Footer>
        <ProgramCardButton
          intakeSearch={props.intake.attributes}
          onClick={props.onSubmit}
          disabled={props.disabled}
          loading={props.loading}
          currentApplication={props.currentApplication}
        />
      </Card.Footer>
    </Card>
  )
}
