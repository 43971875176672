import { RefinedVersionedApplicationResourceWithFiles } from 'applications-types-lib'

import { ApiStudentRequest, request } from '../utils/request'
import { useAuthMutation } from './auth'

type UpdateDecisionLettersParams = {
  applicationId: string
  decision: {
    letterType: 'PRE_OFFER'
    studentResponse: 'PENDING' | 'ACCEPTED' | 'REJECTED'
    reason?: string
  }
}

type RawApplicationsResponse = {
  data: RefinedVersionedApplicationResourceWithFiles
}

export function useUpdateDecisionLetters() {
  const { isPending, mutate, data } = useAuthMutation(
    async (params: UpdateDecisionLettersParams) => {
      return await request<RawApplicationsResponse>(
        new ApiStudentRequest(`/applications/${params.applicationId}/decisions`, {
          method: 'POST',
          body: {
            data: {
              id: params.applicationId,
              type: 'decisions',
              attributes: {
                decision: params.decision,
              },
            },
          },
        }),
        {
          isExpectedResponse: (res): res is RawApplicationsResponse => res,
        },
      )
    },
  )

  return {
    isLoadingUpdateLettersDecision: isPending,
    updateLettersDecision: mutate,
    application: data?.data || null,
  }
}
