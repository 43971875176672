import { useAuthQuery } from './auth'
import { ApiStudentRequest, request } from '../utils/request'
import { FileMetaData, RefinedVersionedApplicationResourceWithFiles } from 'applications-types-lib'
import { SetAllFieldsOptional } from 'schools-domain-backend-utils'
import { ApplicationState } from './useListApplications'
import { ProgramIntake } from '@backend/resources/program-intake/types'
import { isUndefined } from 'lodash'

type GetApplicationProps = {
  id?: string
}

export type RawApplicationResponse = {
  data: RefinedVersionedApplicationResourceWithFiles
}

export type DeferralOptions = {
  availableIntakes: Array<ProgramIntake>
  helperText: string
  reasonsForDeferral: string[]
  deferralState: ApplicationState
}

export type RawApplicationAggregationResponse = {
  data: {
    id: string
    type: 'application_aggregation'
    attributes: {
      application: RefinedVersionedApplicationResourceWithFiles
      deferralOptions: DeferralOptions | null
    }
  }
}

export function useGetApplication(props: GetApplicationProps) {
  const { isLoading, isFetching, data } = useAuthQuery(
    ['applications', props.id],
    async () => {
      return await request<RawApplicationAggregationResponse>(
        new ApiStudentRequest(`/applications/${props.id}`, {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is RawApplicationAggregationResponse => res,
        },
      )
    },
    {
      enabled: !!props.id,
      refetchOnWindowFocus: false,
      select: app => ({ ...app, data: hackActiveFile(app) }),
    },
  )

  const application = data?.data.attributes.application || null
  const deferralOptions = data?.data.attributes.deferralOptions || null

  return {
    isLoadingApplication: isLoading,
    isFetchingApplication: isFetching,
    application,
    deferralOptions,
  }
}

// Until activeRecord is set appropriately, hack it in.
function hackActiveFile(
  app: RawApplicationAggregationResponse,
): RawApplicationAggregationResponse['data'] {
  const sections: string[] = []
  const application = app.data.attributes.application
  if (application.attributes?.files) {
    application.attributes.files = Object.entries(application.attributes.files).reduce(
      (acc, [key, itm]) => {
        if (!itm || !itm.sectionReference) return acc
        if (!isUndefined(itm.activeRecord)) {
          acc[key] = itm
          return acc
        }

        let active = itm.sectionReference === 'other'
        if (!sections.includes(itm.sectionReference)) {
          active = true
          sections.push(itm.sectionReference)
        }

        acc[key] = {
          ...itm,
          activeRecord: active,
        }
        return acc
      },
      {} as Record<string, SetAllFieldsOptional<FileMetaData>>,
    )
  }

  return {
    ...app.data,
    attributes: {
      ...app.data.attributes,
      application,
    },
  }
}
