import {
  Flex,
  GridAlpha,
  Heading,
  Link,
  ProgressIndicator,
  Text,
  useToasts,
} from '@applyboard/crystal-ui'
import { ChevronLeftOutlineIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'
import { useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  ApplicationForms,
  ApplicationHeader,
  ApplicationTimeline,
} from '../../components/Application'
import ApplicationStateTag from '../../components/Application/ApplicationStateTag/ApplicationStateTag'
import { firstTabIncomplete } from '../../components/Application/ApplicationTimeline/utils'
import { Loading } from '../../components/Loading'
import { NavBars } from '../../components/NavBars'
import {
  ApplicationState,
  RawApplicationAggregationResponse,
  RawApplicationResponse,
  useCreateApplication,
  useGetApplication,
  useSubmitApplication,
  useUpdateApplication,
} from '../../hooks'
import { GetProgramIntakeResponse, useGetProgramIntake } from '../../hooks/useGetProgramIntake'
import { GenericError, hasApplicationFee } from '../../utils'
import { convertTimelessDateStrToLocalDate } from '../../utils/convertTimelessDateStrToLocalDate'
import { BackToApplication } from './BackToApplication'
import { CurrentProgramSummaryCard } from './CurrentProgramSummaryCard'
import { DropdownMenu } from './DropdownMenu'
import { IntakeClosedDialog } from './IntakeClosedDialog'
import { ProgramFilters } from './ProgramFilters'
import { ProgramList } from './ProgramList'
import { PayAndSubmitApprovalDialog, SubmitApprovalDialog } from './SubmitApprovalDialog'
import { SubmitConfirmation } from './SubmitConfirmation'

export function CreateApplicationPage() {
  const toast = useToasts()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const currentApplicationId = searchParams.get('applicationId') || ''
  const status = searchParams.get('status') || ''

  const { isLoadingApplication, application } = useGetApplication({
    id: currentApplicationId,
  })
  const { intake } = useGetProgramIntake({
    id: application?.attributes?.programSelected?.programIntakeId,
  })
  const [openIntakeClosedDialog, setOpenIntakeClosedDialog] = useState(false)
  const { isCreatingApplication, createApplication } = useCreateApplication()
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: currentApplicationId,
  })
  const { isSubmittingApplication, submitApplication } = useSubmitApplication({
    id: currentApplicationId,
  })

  const [showSubmitApprovalDialog, setShowSubmitApprovalDialog] = useState(false)
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false)

  const [currentStep, setCurrentStep] = useState(0)
  let selectedTab = currentStep - 1

  useEffect(() => {
    if (status === 'error' || status === 'cancelled') {
      toast.negative(
        new Error(`Your payment could not be processed for application submission. Please make sure you 
           have sufficient funds and your payment details are correct or try an alternative 
           payment method.`),
      )
    } else if (status === 'success') {
      navigate(`/`, { replace: true })
    }
  }, [navigate, status, toast])

  useEffect(() => {
    if (typeof intake?.attributes?.isAvailable !== 'undefined') {
      setOpenIntakeClosedDialog(!intake.attributes.isAvailable)
    }
  }, [intake])

  useEffect(() => {
    if (
      application &&
      application.attributes?.applicationState !== ApplicationState.DRAFT &&
      currentStep < 8
    ) {
      navigate(`/applications/${application.id}`, { replace: true })
      toast.warning(
        'This application was previously submitted. The page has been refreshed to reflect the current status.',
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application, navigate])

  const paySubmitButtonRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    if (showSubmitApprovalDialog === false && paySubmitButtonRef.current) {
      paySubmitButtonRef.current.focus()
    }
  }, [showSubmitApprovalDialog])

  const handleUpdateApplication = (intakeId: string | null) => {
    if (!intakeId) {
      toast.negative(new Error('Failed to update program selection.'))
      return
    }
    updateApplication(
      {
        attributes: {
          programSelected: {
            priority: 0,
            programIntakeId: intakeId,
          },
        },
      },
      {
        onSuccess: response => {
          queryClient.invalidateQueries({ queryKey: ['intake-search'] })
          setSearchParams({ applicationId: response.data.id })
          setCurrentStep(step => step + 1)

          queryClient.setQueryData(
            ['applications', response.data.id],
            (oldData: RawApplicationAggregationResponse): RawApplicationAggregationResponse => {
              return {
                data: {
                  ...oldData.data,
                  attributes: {
                    ...oldData.data.attributes,
                    application: response.data,
                  },
                },
              }
            },
          )

          toast.positive(
            'Your application has been successfully updated! We recommend that you review the requirements to make sure that you provided all the information needed.',
          )
        },
        onError: err => {
          if (err instanceof Error) {
            toast.negative(new Error(err.message))
          }
        },
      },
    )
  }

  const handleCreateApplication = (intakeId: string) => {
    createApplication(
      {
        intakeId,
      },
      {
        onSuccess: response => {
          setSearchParams({ applicationId: response.data.id })
          setCurrentStep(0)
        },
        onError: err => {
          if (err instanceof Error) {
            toast.negative(new Error(err.message))
          }
        },
      },
    )
  }

  const handleSubmitApplication = (
    successCallback: (response: RawApplicationResponse) => void,
    errorCallback: (err: GenericError) => void,
  ) => {
    submitApplication(undefined, {
      onSuccess: response => {
        successCallback(response)
      },
      onError: err => {
        if (err instanceof Error) {
          errorCallback(err)
        }
      },
    })
  }

  const handleSubmitConfirmation = async () => {
    handleSubmitApplication(
      (response: RawApplicationResponse) => {
        queryClient.setQueryData(
          ['applications', response.data.id],
          (oldData: RawApplicationAggregationResponse): RawApplicationAggregationResponse => {
            return {
              data: {
                ...oldData.data,
                attributes: {
                  ...oldData.data.attributes,
                  application: response.data,
                },
              },
            }
          },
        )

        setShowSubmitApprovalDialog(false)
        setShowSubmitConfirmation(true)
      },
      (err: GenericError) => {
        setShowSubmitApprovalDialog(false)
        setShowSubmitConfirmation(false)
        toast.negative(err)
      },
    )
  }

  const handleSubmitApplicationTrigger = async () => {
    await queryClient.invalidateQueries({
      queryKey: ['program-intake', intake?.id],
    })
    const updatedIntake = queryClient.getQueryData([
      'program-intake',
      intake?.id,
    ]) as GetProgramIntakeResponse

    if (!updatedIntake?.data?.attributes?.isAvailable) {
      setOpenIntakeClosedDialog(true)
    } else {
      setShowSubmitApprovalDialog(true)
    }
  }

  if (currentApplicationId && isLoadingApplication) {
    return (
      <NavBars>
        <Loading />
      </NavBars>
    )
  }

  const onPreviousTabButton = () => {
    queryClient.invalidateQueries({
      queryKey: ['applications', application?.id],
    })
    setCurrentStep(selectedTab)
    window.scroll({
      top: 0,
    })
  }

  if (!application || currentStep === 1) {
    return (
      <NavBars maxWidth={680}>
        <Flex gap={6} direction="column">
          <Text>
            <Link
              href={application ? `/applications/${currentApplicationId}` : '/'}
              leadIcon={ChevronLeftOutlineIcon}
              variant="standalone"
            >
              {application ? 'Back to current application' : 'Back to My Applications'}
            </Link>
          </Text>
          {application ? (
            <CurrentProgramSummaryCard
              applicationSummary={{
                programName: application.attributes?.programSelected?.program?.name ?? '',
                campusName: application?.attributes?.programSelected?.campus?.name ?? '',
                intakeTermDate: application?.attributes?.programSelected?.programIntakeTerm
                  ?.startDate
                  ? format(
                      convertTimelessDateStrToLocalDate(
                        application?.attributes?.programSelected?.programIntakeTerm?.startDate,
                      ),
                      'MMM yyyy',
                    )
                  : '',
              }}
            />
          ) : null}
          <Heading level={1} variant="headlineL">
            Program Selection
          </Heading>
          <ProgramFilters />
          <ProgramList
            loading={isCreatingApplication || isUpdatingApplication}
            onSubmit={intakeId => {
              if (!application) {
                handleCreateApplication(intakeId)
              } else {
                handleUpdateApplication(intakeId)
              }
            }}
            currentApplication={application?.attributes}
          />
        </Flex>
      </NavBars>
    )
  }

  if (currentStep < 1) {
    selectedTab = firstTabIncomplete(application)
  }

  return (
    <>
      <NavBars largePage>
        <BackToApplication />
        <Flex direction="column" gap={10}>
          <Flex hideAbove="md" pb={3}>
            <ProgressionWrapper>
              <ProgressIndicator
                variant="linear"
                label="Completion"
                size="lg"
                total={100}
                value={(selectedTab - 1) * (100 / 6)}
              />
            </ProgressionWrapper>
          </Flex>

          <Flex wrap={false} align="center" justify="between" gap={4}>
            <Flex
              hideBelow={selectedTab === 7 && showSubmitApprovalDialog ? 'sm' : undefined}
              align={{ xs: 'start', md: 'center' }}
              gap={4}
              direction={{ xs: 'column', md: 'row' }}
            >
              <Flex hideBelow="md">
                <img src="/application-illustration.svg" alt="New Application" />
              </Flex>
              <Heading level={1} variant="headlineL">
                New Application
              </Heading>
              {application ? (
                <ApplicationStateTag state={application.attributes?.applicationState} />
              ) : null}
            </Flex>
            <DropdownMenu
              application={application}
              onEditProgram={() => {
                setCurrentStep(1)
              }}
            />
          </Flex>
          <GridAlpha
            columnGap={12}
            rowGap={5}
            columns={{
              xs: '1fr',
              md: '336px 1fr',
            }}
            areas={{
              xs: ['aside', 'form'],
              md: ['aside form'],
            }}
          >
            <GridAlpha.Item areaName="aside">
              <Flex direction="column" gap={4}>
                <Flex
                  hideBelow={selectedTab === 7 && showSubmitApprovalDialog ? 'sm' : undefined}
                  direction="column"
                >
                  <ApplicationHeader application={application} />
                </Flex>
                <ApplicationTimeline
                  selectedTab={selectedTab}
                  setSelectedTab={tab => setCurrentStep(tab + 1)}
                  application={application}
                />
              </Flex>
            </GridAlpha.Item>
            <GridAlpha.Item areaName="form">
              <Flex
                grow={1}
                hideBelow={showSubmitApprovalDialog || showSubmitConfirmation ? 'sm' : undefined}
              >
                <ApplicationForms
                  selectedTab={selectedTab}
                  application={application}
                  onPreviousTabButton={onPreviousTabButton}
                  focusRef={paySubmitButtonRef}
                  onSuccess={response => {
                    if (response && selectedTab <= 6) {
                      queryClient.invalidateQueries({
                        queryKey: ['applications', response.data.id],
                      })
                      setCurrentStep(selectedTab + 2)
                    }
                  }}
                  onError={err => {
                    toast.negative(err)
                    queryClient.invalidateQueries({ queryKey: ['applications', application.id] })
                  }}
                  submitApplication={handleSubmitApplicationTrigger}
                />
              </Flex>
              {hasApplicationFee(application) ? (
                <PayAndSubmitApprovalDialog
                  showSubmitApprovalDialog={showSubmitApprovalDialog}
                  setShowSubmitApprovalDialog={setShowSubmitApprovalDialog}
                  application={application}
                />
              ) : (
                <SubmitApprovalDialog
                  showSubmitApprovalDialog={showSubmitApprovalDialog}
                  setShowSubmitApprovalDialog={setShowSubmitApprovalDialog}
                  onSubmitConfirmation={handleSubmitConfirmation}
                  isLoading={isSubmittingApplication}
                />
              )}

              <SubmitConfirmation
                showSubmitConfirmation={showSubmitConfirmation}
                setShowSubmitConfirmation={setShowSubmitConfirmation}
              />
            </GridAlpha.Item>
          </GridAlpha>
        </Flex>
      </NavBars>

      <IntakeClosedDialog
        open={openIntakeClosedDialog}
        onOpenChange={setOpenIntakeClosedDialog}
        onEditProgram={() => setCurrentStep(1)}
      />
    </>
  )
}

const ProgressionWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  '> div': {
    width: '100%',
    '> div:first-of-type': {
      display: 'none',
    },
  },
})
